@font-face {
  font-family: MorrisonsAgenda;
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/MorrisonsAgenda-Light.woff') format('woff');
}

@font-face {
  font-family: MorrisonsAgenda;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MorrisonsAgenda-Medium.woff') format('woff');
}

@font-face {
  font-family: MorrisonsAgenda;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/MorrisonsAgenda-Semibold.woff') format('woff');
}

@font-face {
  font-family: MorrisonsAgenda;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/MorrisonsAgenda-Bold.woff') format('woff');
}

body {
  @apply font-sans antialiased font-normal leading-normal text-body;
  font-family: MorrisonsAgenda, system-ui, BlinkMacSystemFont, -apple-system,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif !important;
}

input,
span,
h1,
h2,
h3,
h4,
p,
th,
td {
  @apply font-sans antialiased font-normal leading-normal text-body;
  font-family: MorrisonsAgenda, system-ui, BlinkMacSystemFont, -apple-system,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif !important;
}

.o-logo {
  width: 80px;
  height: 37px;
  @screen md {
    width: 115px;
    height: 45px;
  }
}

/* 100% Height */
[data-reactroot],
#root,
html,
body {
  @apply h-full;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
  @apply font-light leading-tight;
}

.c-wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mt-10 mb-6 text-lg font-semibold;
    @screen md {
      @apply text-xl;
    }
  }
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    @apply mt-0;
  }
  h2 {
    @apply text-lg;
  }
  section:first-child {
    margin-top: 0 !important;
  }
  li {
    @apply mb-6 text-lg leading-normal;
  }
  p,
  blockquote {
    @apply mb-6 text-lg leading-normal;
  }
  a {
    @apply text-green;
  }
  ul,
  ol {
    @apply pl-0 mb-8 ml-0;
    list-style-position: inside;
  }
  ul li,
  ol li {
    @apply relative overflow-hidden;
  }
  ul li > *,
  ol li > * {
    /*TODO: WHAT IS THIS DOING? @apply absolute pl-6 pin-t;*/
  }

  ul li > *,
  ol li > * {
    @apply inline;
  }

  > img {
    @apply block my-8;
  }
  blockquote {
    @apply pl-8 my-8 border-l-4 ml-1/12 border-primary;
    p {
      @apply .text-green-light text-xl font-medium;
    }
  }
  table {
    @apply w-full my-8 border-collapse;
    th {
      @apply p-4 font-normal text-left text-white bg-green-light;
    }
    td {
      @apply p-4 text-left border;
    }
  }
  img,
  p img,
  p .embedly-card {
    @apply block w-full max-w-sm mb-4;
  }
  img,
  p img {
    border-radius: 0.5rem;
  }
}
@screen md {
  .c-wysiwyg {
    h2 {
      @apply text-xl;
    }
    .youtube {
      flex: 1 1 40%;
    }
  }
}
@screen lg {
  .c-wysiwyg {
    > img,
    > p,
    > li,
    > ul,
    > ol,
    blockquote {
      max-width: 60rem !important;
    }
    li {
      @apply text-xl;
    }
    > p,
    > blockquote {
      @apply text-xl;
    }
    > img {
      @apply w-full mb-8;
    }
    img {
      max-width: 36rem !important;
    }
    blockquote {
      @apply my-10 mr-2/12;
      p {
        @apply text-2xl;
      }
    }
    .youtube {
      flex: 1 1 30%;
    }
  }
}

.c-accordion-panel {
  ul {
    @apply mt-0 mb-4;
  }
}

@screen handheld {
  .container {
    max-width: 700px;
  }
}

@screen xl {
  .container {
    max-width: 1100px;
  }
}

@screen xxl {
  .container {
    max-width: 1280px;
  }
}

.morri-tile .material-icon-wrapper,
.morri-tile img {
  width: 70px !important;
  height: 70px !important;
  @screen md {
    width: 70px !important;
    height: 70px !important;
  }
  @screen xxl {
    width: 80px !important;
    height: 80px !important;
  }
}
.morri-tile .material-icons {
  @screen md {
    font-size: 70px !important;
  }
  @screen xxl {
    font-size: 80px !important;
  }
}

.group-icon img {
  @screen lg {
    width: 30px;
    height: 30px;
  }
}

.o-overlay {
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.isActive .o-overlay {
  opacity: 1;
  pointer-events: auto;
}

.circular {
  animation: rotate 2s linear infinite;
  position: relative;
  width: 100px;
  height: 100px;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #33a9ff;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #004e37;
  }
  40% {
    stroke: #fcbc00;
  }
  66% {
    stroke: #00893f;
  }
  80%,
  90% {
    stroke: #faad63;
  }
}

.fix-border {
  margin-top: -1px;
}
/* Styles links in the FAQ blocks on the Search page */
.faq-text a {
  @apply underline text-primary;
}

.submenu-active .submenu-icon {
  transform: rotate(90deg);
}

.player-wrapper {
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

span.pi{
  font-family: 'primeicons' !important;
}
